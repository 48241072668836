import { useEffect, useRef, useState } from "react";
import About from "./components/About/About";
import Banner from "./components/Banner/Banner";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import Products from "./components/Products/Products";
import Features from "./components/Features/Features";
import ContactForm from "./components/ContactForm/ContactForm";
import logoBlack from "../src/img/aets-logo-new.png";
import { FaArrowUp } from "react-icons/fa6";
import CarouselBanner from "./components/Carousel/CarouselBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

function App() {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const ref = useRef(null);
  const services = useRef(null);
  const home = useRef(null);
  const products = useRef(null);
  const location = useRef(null);
  const about = useRef(null);
  const contact = useRef(null);
  const observer = useRef(null);

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset;
    if (pageYOffset >= 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const visibleSection = entries.find(
        (entry) => entry.isIntersecting
      )?.target;
      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    });

    const sections = document.querySelectorAll("[data-section]");

    sections.forEach((section) => {
      observer.current.observe(section);
    });
    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  return (
    <div ref={ref}>
      <header>
        <div className="main-container">
          <a
            href="javascript:void(0)"
            onClick={() => home.current?.scrollIntoView({ behavior: "smooth" })}
            className="brandLogo"
          >
            <img src={logoBlack} alt="AETS" />
          </a>
          <ul className="header-menu">
            <li>
              <a
                href="javascript:void(0)"
                className={activeSection === "home" ? "active" : ""}
                onClick={() =>
                  home.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={activeSection === "products" ? "active" : ""}
                onClick={() =>
                  products.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Our Products
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={activeSection === "about" ? "active" : ""}
                onClick={() =>
                  about.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={activeSection === "services" ? "active" : ""}
                onClick={() =>
                  services.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={activeSection === "location" ? "active" : ""}
                onClick={() =>
                  location.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Location
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={activeSection === "contact" ? "active" : ""}
                onClick={() =>
                  contact.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </header>
      <div ref={home} data-section id="home">
        <CarouselBanner />
      </div>
      <div ref={products} data-section id="products">
        <Products />
      </div>
      <Features />
      <div ref={about} data-section id="about">
        <About />
      </div>
      <div ref={services} data-section id="services">
        <Services />
      </div>
      <div className="map" ref={location} data-section id="location">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.3690806853297!2d92.72905827452828!3d11.668234142112677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30889588580ab67b%3A0xac95ee5255157843!2sAndaman%20Electro-Tech%20Solution%20(A-E-T-S)!5e0!3m2!1sen!2sin!4v1705254716125!5m2!1sen!2sin&z=20"
          allowtransparency="true"
          width="100%"
          height="500"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          frameborder="0"
        ></iframe>
      </div>
      <div ref={contact} data-section id="contact">
        <ContactForm />
      </div>
      <Footer />
      {showBackToTop && (
        <a
          href="javascript:void(0)"
          id="backToTop"
          onClick={() => ref.current?.scrollIntoView({ behavior: "smooth" })}
        >
          <FaArrowUp />
        </a>
      )}
    </div>
  );
}

export default App;
