import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ledProductImg from "../../img/banner-img2.jpg";
import electricalServiceImg from "../../img/banner-img.jpg";
import telecomServiceImg from "../../img/telecom-tower.png";
import telecomMaintenanceImg from "../../img/telcom.png";
import "./CarouselBanner.scss";

function CarouselBanner() {
    return (
        <Carousel data-bs-theme="light">
            <Carousel.Item className="banner">
                <img
                    className="d-block w-100"
                    src={ledProductImg}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h1>LED Light Products <span>For Your Space</span></h1>
                    <p>Whether you need lighting repairs, electrical upgrades, or a new installation, we’re here to provide quality services that meet your needs and exceed your expectations.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="banner">
                <img
                    className="d-block w-100"
                    src={telecomServiceImg}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h1>Telecom Base Station Services <span>Your Network, Our Expertise!</span></h1>
                    <p>24/7 Emergency Services: Promote your around-the-clock service availability for urgent repairs and maintenance.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="banner">
                <img
                    className="d-block w-100"
                    src={electricalServiceImg}
                    alt="Third slide"
                />
                <Carousel.Caption>
                    <h1>Electrical Repair - DG Installations <span>Fulfilling Electrical Solutions for a Bright Future.</span></h1>
                    <p>Your Trusted Partner for All Electrical Needs — From Installations to Repairs!</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="banner">
                <img
                    className="d-block w-100"
                    src={telecomMaintenanceImg}
                    alt="Forth slide"
                />
                <Carousel.Caption>
                    <h1>Cell Site maintenance <span>Expert in Cell Site Maintenance Services!</span></h1>
                    <p>Ensure your cell sites operate at maximum efficiency.Contact us to schedule a maintenance consultation!</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselBanner;
