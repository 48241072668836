import "./Services.scss";
import servicesBulb from "../../img/services-bulb.png";

export default function Services() {
  return (
    <section className="services-container">
      <div className="main-container">
        <h6>OUR SERVICES</h6>
        <h2>Full range of services</h2>
        <div className="services-row">
          <div className="col-1">
            <ul>
              <li>
                <h4 className="icon-text">01.</h4>
                <div className="services-content">
                  <h5> Electrical repairs </h5>
                  <p>
                    Our electronic services include installation, maintenance,
                    and repair of all types of electrical systems for
                    residential and commercial buildings.
                  </p>
                </div>
              </li>
              <li>
                <h4 className="icon-text">02.</h4>
                <div className="services-content">
                  <h5> Safety improvement </h5>
                  <p>
                    Looking for energy-efficient lighting solutions? Our experts
                    can help you choose the right LED lighting systems that meet
                    your needs and budget.
                  </p>
                </div>
              </li>
              <li>
                <h4 className="icon-text">03.</h4>
                <div className="services-content">
                  <h5> Lighting upgrades </h5>
                  <p>
                    From outdoor landscape lighting to indoor ambient lighting,
                    our team of professionals can create a customized lighting
                    plan that enhances your space.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-2">
            <img src={servicesBulb} alt="led bulb" />
          </div>
          <div className="col-3">
            <ul>
              <li>
                <div className="services-content">
                  <h5> Telecom Infrastructure </h5>
                  <p>
                    Routine inspections, upgrades, and repairs of telecom tower equipment,
                    including antennas, base stations, and associated hardware.Diagnose and
                    troubleshoot network performance issues, ensuring minimal downtime and
                    quick restoration of services.
                  </p>
                </div>
                <h4 className="icon-text">04.</h4>
              </li>
              <li>
                <div className="services-content">
                  <h5> Panel upgrades </h5>
                  <p>
                    Upgrade your electrical panel to improve energy efficiency,
                    increase capacity, and protect your home or business from
                    power surges and electrical fires.
                  </p>
                </div>
                <h4 className="icon-text">05.</h4>
              </li>
              <li>
                <div className="services-content">
                  <h5> Cable installation </h5>
                  <p>
                    Whether you need lighting repairs, electrical upgrades, or a
                    new installation, we're here to provide quality services
                    that meet your needs and exceed your expectations.
                  </p>
                </div>
                <h4 className="icon-text">06.</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
